import React, { useState } from "react";
import "./index.css";
import SEO from "../../components/SEO";
import HeroComponent from "../../components/Hero";
import './index.css'
import { Button, Col, Image, Row } from "react-bootstrap";
import { Fade, Zoom } from "react-reveal";
import Slider from "react-slick";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import Sap from "../../assests/images/sap.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import Proddev from "../../assests/images/prod-dev.png";
import Ourtech from "../../assests/images/our-tech.png";
import homeSliderImage1 from "../../assests/images/homeSliderImage1.png";
import homeSliderImage2 from "../../assests/images/homeSliderImage2.png";
import homeSliderImage3 from "../../assests/images/homeSliderImage3.png";
import homeSliderImage4 from "../../assests/images/homeSliderImage4.png";
import homeSliderImage5 from "../../assests/images/homeSliderImage5.png";
import homeSliderImage6 from "../../assests/images/homeSliderImage6.png";
import homeSliderImage7 from "../../assests/images/homeSliderImage7.png";
import { submitContactForm, uploadFile } from "../../services";
import CustomToast from "../../components/Toast";
import TBLogo from "../../assests/images/TBLogo.png";
import AppFutura from "../../assests/images/appfutura-badge.jpg";
import HelmetComponent from "../../components/Helmet";
import { useHistory } from 'react-router-dom';

import diamondLudo from "../../assests/images/Diamond_Ludo.png";
import kheloAdda from "../../assests/images/Khelo_Adda.png";
import DCpool from "../../assests/images/DC_Pool.png";
import kwelibuy from "../../assests/images/Kwelibuy.png";
import realLudo from "../../assests/images/Ludo_Real.png";
import winNow from "../../assests/images/Win_Now.png";
import DCrummy from "../../assests/images/DC_Rummy.png";
import pocket7 from "../../assests/images/Pocket_7.png";
import eziVote from "../../assests/images/EZI_Vote.png";
import wingsPay from "../../assests/images/Wing_Pay.png";
import betcricks from "../../assests/images/BetCricks.png";

import gameIcon from "../../assests/images/Group.png";
import softwareIcon from "../../assests/images/Vector.png";
import productIcon from "../../assests/images/Vector (1).png";
import devGameIcon from "../../assests/images/dev-game-icon.png";
import devDesktopIcon from "../../assests/images/dev-desktop-icon.png";
import DevProductIcon from "../../assests/images/dev-product-icon.png";
import callIcon from "../../assests/images/Call.svg";
import circleImg_1 from "../../assests/images/Group 28.png";
import circleImg_2 from "../../assests/images/Group 26.png";
import circleImg_3 from "../../assests/images/Group 27.png";
import ourProcess from "../../assests/images/processDesign.png";

import whyUsImg1 from "../../assests/images/image 14.png";
import whyUsImg2 from "../../assests/images/image 15.png";
import whyUsImg3 from "../../assests/images/image 16.png";
import whyUsImg4 from "../../assests/images/image 17.png";
import whyUsImg5 from "../../assests/images/image 18.png";
import whyUsImg6 from "../../assests/images/image 19.png";
import whyUsImg7 from "../../assests/images/image 20.png";
import whyUsImg8 from "../../assests/images/image 21.png";
import whyUsImg9 from "../../assests/images/image 22.png";

import Ecom from "../../assests/image/ecom.png"
import Food from "../../assests/image/food.png"
import Astro from "../../assests/image/astrology.png"
import Ride from "../../assests/image/ride.png"
import Auto from "../../assests/image/auto.png"
import Health from "../../assests/image/health.png"
import Blog from "../../assests/image/blog.png"
import Fintech from "../../assests/image/fintech.png"
import Real from "../../assests/image/realstate.png"
import EdTech from "../../assests/image/edTech.png"
import MobApp from "../../assests/image/mobileApp.png"
import WebApp from "../../assests/image/webApp.png"
import EntPriseApp from "../../assests/image/enterpriseApp.png"
import Process from "../../assests/image/process.png"
import Stack from "../../assests/image/techStack.png"


const LandingPage = () => {
  const history = useHistory();
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    phone: "+91", // Default value with +91 prefix
    email: "",
    projectdescription: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // Validation function for enabling/disabling submit button
  const isFormValid = Object.values(contactFormFields).every(field => field !== '');

  const handleShowToast = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactFormFields({
      ...contactFormFields,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { email, phone } = contactFormFields;
    const phoneWithoutPrefix = phone.replace("+91", ""); // Remove +91 prefix

    // Email should contain "@" symbol
    if (!email.includes('@')) {
      handleShowToast("Please enter a valid email address with '@'.");
      return false;
    }

    // Phone without +91 should start with 6, 7, 8, or 9 and be exactly 10 digits
    if (!/^[6-9]\d{9}$/.test(phoneWithoutPrefix)) {
      handleShowToast('Phone number must start with 6, 7, 8, or 9 and be 10 digits.');
      return false;
    }

    return true;
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    // Perform validation checks
    if (!validateForm()) return;

    const payload = {
      fields: [
        { name: "email", value: contactFormFields.email },
        { name: "name", value: contactFormFields.name },
        { name: "phone", value: contactFormFields.phone },
        { name: "projectdescription", value: contactFormFields.projectdescription },
      ],
    };

    const res = await submitContactForm(payload); // Replace with actual API call
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "+91", // Reset phone field with +91 prefix
        projectdescription: "",
      });
      handleShowToast("We'll get back to you soon!");
      history.push("/thankyou-page");
    } else {
      handleShowToast("Some error occurred. Please try again!");
    }
  };

  let features = [
    {
      icon: "150+",
      name: "Projects",
      marginTop: false,
    },
    {
      icon: "70+",
      name: "Mobile App",
      marginTop: true,
    },
    {
      icon: "40+",
      name: "Blockchain",
      marginTop: false,
    },
    {
      icon: "50+",
      name: "Happy Clients",
      marginTop: true,
    },
    {
      icon: "70+",
      name: "Expert Teams",
      marginTop: false,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 648,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: homeSliderImage1,
      name: "Noah William",
      id: 1,
      para: "We would like to express our satisfaction in the cooperation regarding the development of our Poker and Rummy Games applications. George and the development team did a very professional job. We are satisfied with the solutions given to us and with the communication flow through the project.",
    },
    {
      icon: homeSliderImage2,
      name: "Joyce Barret",
      id: 2,
      para: "Digital Crew has delivered great results on time. We have worked with them for developing Sports Betting software. All through the working tenure, we found their team energetic and highly professional in working. Looking forward to working with them again",
    },
    {
      icon: homeSliderImage3,
      name: "Richard Samuel",
      id: 3,
      para: "We would like to recommend the Digital Crew development team. We have got solutions from them in iGaming; They have capabilities to handle multiple projects in Card Gaming, Fantasy Sports, Sports Book and got the games delivered to our company on time. We look forward to working with them in future projects",
    },
    {
      icon: homeSliderImage4,
      name: "Tim Larson",
      id: 4,
      para: "Job well done and I hope we can continue to grow together. Thank you for the services your team has provided. Your team has been a pleasure to work with, professional and timely.",
    },
    {
      icon: homeSliderImage5,
      name: "Kyle James",
      id: 4,
      para: 'The Digital Crew is always available, extremely knowledgeable, and have a tremendous "can-do" attitude. We have worked on a Sports Book with them and they provided the best solutions.',
    },
    {
      icon: homeSliderImage6,
      name: "Benjamin Liam",
      id: 4,
      para: "I would like to thank Digital Crew and George for all the efforts and suggestions that assisted us in providing Blockchain solutions to us in the area of web, technology.",
    },
    {
      icon: homeSliderImage7,
      name: "Oli John",
      id: 4,
      para: "I worked with George and team Digital Crew for several years on various projects. Unflinchingly reliable, professional and proactive from a technology and security standpoint.",
    },
  ];





  return (
    <>
      <SEO
        title="Welcome to Digital Crew - Transform Your Ideas with Us"
        description="Explore Digital Crew's innovative solutions in Mobile Apps, Software, Web Designing, and Development. Discover how we can bring your digital dreams to life."
        url="https://digitalcrew.co.in/landing-page"
      />
      <HeroComponent
        bigText={
          "<span className='redQuotes'>“</span>Unleash Your Digital<b/> Dreams with Digital Crew<span className='redQuotes'>”</span>"
        }
        smallText={
          "Transform Your Game Idea with Our Expert Solutions."
        }
        buttonText={"Talk with the Experts"}
        page={"landing-page-new"}
      ></HeroComponent>
      <div className="industry">
        <div className="section-one">
          <Image src={Ecom} />
          <Image src={Food} />
          <Image src={Astro} />
          <Image src={Ride} />
          <Image src={Auto} />
        </div>
        <div className="section-two">
          <Image src={Health} />
          <Image src={Blog} />
          <Image src={Fintech} />
          <Image src={Real} />
          <Image src={EdTech} />
        </div>
      </div>
      <p style={{ fontSize: "16px", padding: "30px" }}>
        <span style={{ color: "#EE3A38", textAlign: "center", }}>Digital Crew</span>{" "}is your trusted guide in the Digital World. From customized Software development to Enterprise Solutions,
        We help you navigate the ever-changing online landscape with expertise and care.
      </p>
      <div className="software-type">
        <div>
          <h5 style={{ color: "#EE3438" }}>Mobile App</h5>
          <h6 >Development Services</h6>
          <p>Our Mobile App Development services offer
            custom solutions tailored to meet your business
            needs, ensuring seamless user experiences across
            both iOS and Android platforms. Digital Crew's team
            of expert developers utilizes cutting-edge technologies
            to build high-performance mobile applications that are
            scalable, secure, and easy to use. From concept design
            to deployment, Digital Crew provide end-to-end app
            development services focusing on delivering robust,
            feature-rich apps that drive engagement. Whether you're
            looking for a native, hybrid, or cross-platform solution,
            we help you turn ideas into powerful mobile experiences that
            help grow your business.
          </p>
        </div>
        <div >
          <Image src={MobApp} className="software-type-image" />
        </div>
      </div>
      <div className="software-type1">
        <div >
          <Image src={WebApp} className="software-type-image" />
        </div>
        <div>
          <h5 style={{ color: "#EE3438" }}>Web App</h5>
          <h6 >Development Services</h6>
          <p>
            Our web app development services offer tailored
            solutions to transform your business with cutting-edge,
            scalable, and user-friendly applications. Whether you need
            custom web apps, e-commerce platforms, or enterprise solutions,
            Digital Crew expert developers ensure high-performance and responsive
            design. We specialize in full-stack development using the latest technologies
            like React, Node.js, and Python to deliver robust applications.
            From concept to deployment, we provide seamless integration,
            security, and continuous support. Elevate your online presence
            with our top-notch web application development services designed
            for businesses looking to grow in the digital landscape.
          </p>
        </div>
      </div>
      <div className="software-type">
        <div>
          <h5 style={{ color: "#EE3438" }}>Enterprise App</h5>
          <h6 >Development Services</h6>
          <p>
            Our Enterprise App Development services deliver customized,
            scalable solutions to enhance your business operations.
            Digital Crew team  specialize in building high-performance,
            secure enterprise applications tailored to your unique needs,
            ensuring seamless integration with existing systems.
            From cloud-based solutions to mobile and web platforms,
            we leverage the latest technologies for optimal efficiency
            and user experience. Our expert team provides end-to-end
            development services, including consultation, design, development,
            and ongoing support partners with us to accelerate your digital
            transformation and achieve sustainable growth with cutting-edge
            enterprise applications.
          </p>
        </div>
        <div >
          <Image src={EntPriseApp} className="software-type-image" />
        </div>
      </div>

       <div className="process">
       <h4 style={{padding:"20px"}}>How We Work</h4>
       <Image src={Process}/>
       </div>
       <div className="process" style={{paddingBottom:"20px"}}>
       <h4 style={{padding:"20px"}}>Tech Stack</h4>
       <Image src={Stack}/>
       </div>
   
     
    {/* <div className="contact-section">
      <div className="contact-box">
        <Row>
          <Col lg={8}>
            <form encType="multipart/form-data" onSubmit={submitFormHandler}>
              <div className="contact-form">
                <input
                  className="contact-input"
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  onChange={handleChange}
                  value={contactFormFields.name}
                />
                <Row>
                  <Col lg={6}>
                    <input
                      className="contact-input contact-number"
                      type="text"
                      placeholder="Contact Number"
                      name="phone"
                      onChange={handleChange}
                      value={contactFormFields.phone}
                    />
                  </Col>
                  <Col lg={6}>
                    <input
                      className="contact-input"
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      onChange={handleChange}
                      value={contactFormFields.email}
                    />
                  </Col>
                </Row>
                <textarea
                  className="textarea"
                  placeholder="Project Description"
                  name="projectdescription"
                  onChange={handleChange}
                  value={contactFormFields.projectdescription}
                ></textarea>
                <Row className="bottom-row">
                  <Col lg={12}>
                    <Button
                      className="btn contact-form-btn"
                      type="submit"
                      disabled={!isFormValid} // Enable button only if form is valid
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
          <Col lg={4}>
            <div className="right-side-inner">
              <p className="bigtext">
                Let's Get into
                <br />
                <span className="red-text">Business discussions</span> !!
              </p>
              <p className="smallText">
                Our strength lies in the comprehensive suite of services we
                offer to our clients.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div> */}
    <CustomToast show={showToast} onClose={handleHideToast} message={toastMessage} />
    </>
  );
};

export default LandingPage;