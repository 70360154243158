import React, { useEffect } from 'react'
import HelmetComponent from '../components/Helmet';
import HeroComponent from '../components/Hero'
import PortfolioCard from '../components/PortfolioCard'
import SEO from '../components/SEO';

function Portfolio() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>

    <SEO
        title="Contact Us - Digital Crew"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/contact-us"
      />
            <HelmetComponent title={"Industries - Digital Crew"}/>  
          
            <HeroComponent
                bigText={"Passionate about making an impact"}
               // smallText={"Our software development services are built to evolve your business idea into a successful growth story."}
              //  buttonText={"Consult Our Experts"}
                page={"portfolio"}
            ></HeroComponent>
            <div className="portfolio-section">
                <PortfolioCard
                    heading={"iGaming"}
                    text={"Best gaming solution required a fusion of the latest technology, artistry, and creative and innovative ideas that give rise to an engaging, graphical and feature-rich gaming solution. Integration of comprehensive business intelligence and reporting features into your gaming solution will provide you with all you need to analyze and improve your business strategies and achieve better performance. Our experts cover all facets of creating advanced desktop and mobile gaming solutions as per your requirement."}
                    rightImage={"iGaming"}
                    red
                ></PortfolioCard>
                <PortfolioCard
                    heading={"Blockchain"}
                    text={"Digital Crew is a full-stack crypto services platform; we create smarter and more efficient multiple blockchain solutions. We help you here with the best tamperproof data store to keep your data safe and secure. Customized blockchain service is our specialty to create a wonderful experience for the users. Digital Crew offers the best services to decentralized blockchain networks that increased traceability and security of data and transactions."}
                    rightImage={"nodes"}
                
                ></PortfolioCard>
                  <PortfolioCard
                    heading={"Mobile App"}
                    text={"Mobile application development industries are going through a transformative phase. We cater to the latest technology that can be able to run mobile applications on multiple platforms. We dig into the mobile app usage statistics to understand more about user experience. Every single process of mobile app development service is specially handled by our experts to deliver you a highly featured mobile app solution."}
                    rightImage={"mobileapp"}
                    red
                ></PortfolioCard>
                  <PortfolioCard
                    heading={"Web App"}
                    text={"Web app development services help you to design, build, and evolve the best web-based software. Our expert in web app development created a diverse range of highly innovative, visually appealing, and competent web apps. We have customized web app development irrespective of the size of your business. If you are a startup person, we have a team of experts you can consult for a better understanding of the web development solution"}
                    rightImage={"computer"}
                
                ></PortfolioCard>
            </div>
        </>
    )
}

export default Portfolio