import React from "react";
import HeroComponent from "../components/Hero";
import SEO from "../components/SEO";
const Policy = () => {
  return (
    <>
    <SEO
        title="Privacy Policy - Digital Crew"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/privacy-policy"
      />
      <HeroComponent
        // bigText={
        //   "<span class='redQuotes'>“</span>The biggest part of our digital transformation is ever-changing the way we expect.<span class='redQuotes'>”</span>"
        // }
        smallText={
          "Privacy Policy for Digital Crew"
        }
        // buttonText={"Talk To Us"}
        page={"games"}
      ></HeroComponent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center,",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1366px",
          margin: "0px auto",
          padding: "50px",
          backgroundColor: "#f4f4f4",
        }}
      >
        {/*About Content  */}

        <div
          style={{
            width: "100%",
            padding:"5px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Privacy Policy
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            This Privacy Policy outlines the privacy and data protection
            practices followed by Digital Crew, India (hereinafter referred to
            as “Digital Crew, India”), its affiliates and unaffiliated third
            parties with respect to information gathering, dissemination and
            protection.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            padding:"5px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Policy Statement
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India shall ensure that Personal Data relating to
            natural persons including employees (current as well as former)
            suppliers, and customers, are obtained and processed fairly and in
            accordance with the data subjects’ rights under Data Protection Laws
            and Regulations. Digital Crew, India respects the privacy and is
            committed to promoting the responsible use of personal information
            and protecting individual’s privacy rights.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            padding:"5px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Data collection, onward transfer & processing
          </p>

          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India may collect, store, use and disclose information
            about individuals which may constitute personal data (including
            sensitive personal data under various Government Laws e.g. Indian IT
            Act Privacy rules, GDPR, etc.) for lawful, explicit and legitimate
            purposes and for further processing of personal data consistent with
            those purposes.
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India shall obtain consent from the data subject
            wherever appropriate and required for the processing of personnel
            information prior to collecting, storing and processing any personal
            data.
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India shall not utilize an individual’s personal data
            beyond the scope granted without prior consent from the individual
            and shall take measures to ensure that this principle is observed.
            An individual’s personal data shall not be provided or otherwise
            disclosed to third parties other than Digital Crew, India
            affiliates, investigators, or law enforcement personnel when consent
            has been obtained from the individual in question or when disclosure
            is legally mandated. To the extent permitted by applicable law,
            Digital Crew, India may record and monitor electronic and voice
            communications to ensure compliance with the legal and regulatory
            obligations and internal policies and for the purposes outlined
            above.
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Any transfer of personal data to a third party shall take place only
            if, all necessary controls for Data protection are applied by the
            third party in order to ensure that the level of protection of
            personal data is guaranteed.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            padding:"5px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Confidentiality and Security
          </p>

          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India takes prudent steps to safeguard the
            confidentiality and security of all personal data including taking
            procedural and organizational steps to protect personal data. These
            steps include but are not limited to entering into written
            agreements with all its vendors who process personal data which
            confirm with necessary data protection regulations.
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            In addition, Digital Crew, India strives to protect personally
            identifiable information that it maintains or disseminate so it is
            not obtained by unauthorized individuals or used in unauthorized
            ways, including through the use of appropriate administrative,
            physical, and technical safeguards. E.g. Policies and procedures,
            Physical and logical access controls and encryptions.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            padding:"5px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Data subject access, correction & deletion
          </p>

          <p
            style={{
              color: "#696969",
            }}
          >
            Digital Crew, India recognizes the right of data subjects at
            reasonable intervals to seek / request a copy of the personal data
            held in relation to them by Digital Crew, India. If any personal
            data is found to be wrong, the individual concerned has the right to
            ask us to amend, update or delete it, as appropriate. In some
            circumstances individuals also have a right to object to the
            processing of their personal data as per the prevailing laws.
            Digital Crew, India shall process the personal data solely in
            accordance with the business purposes for which it was intended.
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Privacy consent can be withdrawn easily and at any time by the data
            subject by informing to appropriate authority within Digital Crew,
            India or emailing us at connect@digitalcrew.co.in
          </p>
          <p
            style={{
              color: "#696969",
            }}
          >
            Personal data will not be retained/stored for a period more than
            necessary to fulfil the purposes outlined in this privacy statement.
            The personal data shall be deleted from the systems after evaluating
            if there are any legal, statutory, contractual or legitimate purpose
            that expects Digital Crew, India to retain data.
          </p>
        </div>
        <div style={{ width: "100%", padding:"5px" }}>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>Definitions</p>
          <p style={{ color: "#696969" }}>
            <span
              style={{ fontSize: "16px", fontWeight: "600", color: "black" }}
            >
              Personal Data
            </span>
             means any information relating to a living individual who can be
            identified directly or indirectly by an identifier such as :
          </p>
          <ul>
            <li>
              Name, identification number, image, location data or an online
              identifier.
            </li>
            <li>
              One or more factors specific to the physical, physiological,
              genetic, mental, economic, cultural or social identity of such
              individual.
            </li>
            <li>
              Birth date, address, personal phone number, personal email
              address, race, nationality, ethnicity, origin, colour, religious
              or political beliefs or associations, age, sex, sexual
              orientation, marital status, family status, identifying number,
              code, fingerprints, blood type, inherited characteristics, health
              care history (including information on physical/mental
              disability); and educational, financial, criminal, history.
            </li>
            <li>Photographs of employee and internal gatherings.</li>
          </ul>
          <p style={{ color: "#696969" }}>
            <span
              style={{ fontSize: "16px", fontWeight: "600", color: "black" }}
            >
              Lawful processing
            </span>
             means that the activity is conducted in accordance with applicable
            national or international laws. Specified purpose means being clear
            from the outset about why we are collecting personal data and are
            transparent about our purposes with the individuals concerned.
            Accurate means that the data collected and stored are correct and
            their integrity is protected. Adequate, relevant and not excessive
            means that data should be sufficient for the intended purpose and
            that we should not hold more data than necessary for that purpose.
          </p>
          <p style={{ color: "#696969" }}>
            <span
              style={{ fontSize: "16px", fontWeight: "600", color: "black" }}
            >
              Data Protection Laws and Regulations
            </span>
             means, in the European Union, the Data Protection Directive
            95/46/EC and the national statutory legislation passed in each
            Member State implementing this Directive, the General Data
            Protection Regulation (GDPR) 2016 / 679, as well as national law
            that exists outside the EU in each country.
          </p>
          <p style={{ color: "#696969" }}>
            <span
              style={{ fontSize: "16px", fontWeight: "600", color: "black" }}
            >
              European Union
            </span>
             means the current EU Member State countries of:  Austria, Belgium,
            Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia,
            Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia,
            Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal,
            Romania, Slovakia, Slovenia, Spain, Sweden, and the United Kingdom.
          </p>
        </div>
        <div style={{ width: "100%"}}>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            Changes to the policy
          </p>
          <p style={{ color: "#696969" }}>
            Digital Crew, India reserves the right to modify or amend this
            Policy at its own discretion. This policy is effective from June
            2019. In order to check updates to this policy, please consult this
            page on a regular basis.
          </p>
        </div>
        <div style={{ width: "100%"}}>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            Additional information
          </p>
          <p style={{ color: "#696969" }}>
            If you would like to know more about Digital Crew, India’s Privacy
            program, please Email us at  connect@digitalcrew.co.in
          </p>
        </div>
      </div>
    </>
  );
};

export default Policy;
