import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { submitContactForm } from "../../services";
import { useHistory } from 'react-router-dom';
import TelegramQR from "../../../src/assests/images/telegram_QR.png"
import WhatsAppQR from "../../../src/assests/images/WhatsApp_QR.png"
import Computer from "../../../src/assests/images/computerVector.png"
import LandingImage from "../../../src/assests/images/landingImage.png"
import DCLOGOBlack from '../../../src/assests/images/DClogo-footer.png';
import "./index.css"
import CustomToast from "../../components/Toast";

function HeroComponent(props) {
    const {
        bigText,
        smallText,
        buttonText,
        page,
      } = props;
      
      const history = useHistory();
      const [showToast, setShowToast] = useState(false);
      const [toastMessage, setToastMessage] = useState("");
    
    
      const handleShowToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
      };
    
      const handleHideToast = () => {
        setShowToast(false);
      };

      const [contactFormFields, setContactFormFields] = useState({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
    });
    const [selectedGame, setSelectedGame] = useState(null);
    const [step, setStep] = useState(1);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactFormFields({
            ...contactFormFields,
            [name]: value,
        });
    };

    const submitFormHandler = async (e) => {
        e.preventDefault();

        const payload = {
            fields: [
                {
                    name: "email",
                    value: contactFormFields.email,
                },
                {
                    name: "name",
                    value: contactFormFields.name,
                },
                {
                    name: "phone",
                    value: contactFormFields.phone,
                },
                {
                    name: "projectdescription",
                    value: contactFormFields.projectdescription,
                },
                {
                    name:"Game Interested In",
                    value:selectedGame,
                }
            ],
        };
        const res = await submitContactForm(payload);
        if (res?.inlineMessage === "Thanks for submitting the form.") {
            setContactFormFields({
                name: "",
                email: "",
                phone: "",
                projectdescription: "",
            });
            history.push("/thankyou-page/igaming");
        } else {
            console.log("Error submitting the form");
        }
    };

    const submitFormHandler2 = async (e) => {
      e.preventDefault();
         
      if (!validateForm()) return;

      const payload = {
          fields: [
              {
                  name: "email",
                  value: contactFormFields.email,
              },
              {
                  name: "name",
                  value: contactFormFields.name,
              },
              {
                  name: "phone",
                  value: contactFormFields.phone,
              },
              {
                  name: "projectdescription",
                  value: contactFormFields.projectdescription,
              },
          ],
      };
      const res = await submitContactForm(payload);
      if (res?.inlineMessage === "Thanks for submitting the form.") {
          setContactFormFields({
              name: "",
              email: "",
              phone: "",
              projectdescription: "",
          });
          history.push("/thankyou-page/software-development");
      } else {
          console.log("Error submitting the form");
      }
  };

    const handleGameSelect = (game) => {
        setSelectedGame(game);
    };

    const handleNextStep = () => {
        if (selectedGame) {
            setStep(2); // Move to contact form step
        }
    };

   
    const validateForm = () => {
      const { email, phone } = contactFormFields;
  
      // Check for "@" in email
      if (!email.includes('@')) {
          handleShowToast("Please enter a valid email address with '@'.");
          return false;
      }
  
      // Remove all non-digit characters from the phone number
      const cleanedPhone = phone.replace(/\D/g, '');
  
      // Validate phone number format
      // if (!/^[6-9]\d{9}$/.test(cleanedPhone)) {
      //     handleShowToast('Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits.');
      //     return false;
      // }
  
      return true;
  };
  


    


    const styles = {
      container: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f2f2f2',
      },
      formContainer: {
          width: '325px',
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
      },
      heading: {
          marginTop: 0,
          fontSize: '20px',
          color: '#333',
      },
      subText: {
          fontSize: '14px',
          color: '#666',
          marginBottom: '20px',
      },
      input: {
          width: '100%',
          padding: '10px',
          marginBottom: '15px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          fontSize: '14px',
      },
      textarea: {
          width: '100%',
          padding: '10px',
          marginBottom: '15px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          fontSize: '14px',
          resize: 'none',
          height: '80px',
      },
      button: {
          width: '100%',
          padding: '10px',
          backgroundColor: '#ff4a4a',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          cursor: 'pointer',
      },
      buttonHover: {
          backgroundColor: '#e43e3e',
      },
  };
    
    return (
        <>
          
         {page === "landing-page-new"?(
            <div
            className="design-component-container"
            style={{ backgroundImage: `url(${LandingImage})` }}
          >
            {/* Logo in the top-left corner */}
            <div className="design-component-logo-container">
              <img src={DCLOGOBlack} alt="Logo" className="design-component-logo-image" />
            </div>
      
            {/* Left Container for texts and stats */}
            <div className="design-component-left-container">
              <h1 className="design-component-title">
                <span style={{ color: "#e63946" }}>EMPOWERING</span> YOUR IDEAS,
                <br />
                <span className="design-component-highlight">ELEVATING YOUR <span style={{ color: "#e63946" }}>BUSINESS</span></span>
              </h1>
              <p className="design-component-subtext">
                "India's Top Software Development Company"
              </p>
              <div className="design-component-stats-container">
                <div className="design-component-stat-box" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.5)", paddingRight:"30px", borderRadius: "5px 0 0 5px" }}>
                  <p className="design-component-stat-number">100+</p>
                  <p className="design-component-stat-label">HAPPY CLIENTS</p>
                </div>
                <div className="design-component-stat-box" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.5)", paddingRight:"20px", borderRadius: "5px 0 0 5px" }}>
                  <p className="design-component-stat-number">200+</p>
                  <p className="design-component-stat-label">PROJECTS</p>
                </div>
                <div className="design-component-stat-box">
                  <p className="design-component-stat-number">100+</p>
                  <p className="design-component-stat-label">EXPERT TEAM</p>
                </div>
              </div>
            </div>
      
            {/* Right Container for images and button */}
            <div className="design-component-right-container">
              {/* <img src={Computer} alt="Computer" className="design-component-computer-image" />
              <div className="design-component-qr-container">
                <img src={TelegramQR} alt="Telegram QR" className="design-component-qr-image" />
                <button className="design-component-button">CONTACT US</button>
                <img src={WhatsAppQR} alt="WhatsApp QR" className="design-component-qr-image" />
              </div> */}
               <div style={styles.formContainer}>
                <h2 style={styles.heading}>Contact Us</h2>
                <p style={styles.subText}>Get Response Within 24 Hours</p>
                <form onSubmit={submitFormHandler2}>
                    <style>
                        {`
                            .input-style {
                                width: 100% !important;
                                padding: 10px !important;
                                margin-bottom: 15px !important;
                                border: 1px solid #ccc !important;
                                border-radius: 5px !important;
                                font-size: 14px !important;
                            }
                        `}
                    </style>
                    <input
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                        value={contactFormFields.name}
                        onChange={handleChange}
                        required
                        className="input-style"
                    />
                    <input
                        type="text"
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        value={contactFormFields.mobile}
                        onChange={handleChange}
                        required
                        className="input-style"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        value={contactFormFields.email}
                        onChange={handleChange}
                        required
                        className="input-style"
                    />
                    <textarea
                        name="projectdescription"
                        placeholder="Write Your Message"
                        value={contactFormFields.projectdescription}
                        onChange={handleChange}
                        required
                        style={styles.textarea}
                    ></textarea>
                    <button
                        type="submit"
                        style={styles.button}
                        onMouseOver={(e) =>
                            (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)
                        }
                        onMouseOut={(e) =>
                            (e.target.style.backgroundColor = styles.button.backgroundColor)
                        }
                    >
                        SUBMIT
                    </button>
                </form>
            </div>
            </div>
          </div>
         ) : page === "landing-page" ? (
                <Container className="hero-container-landing-page-bg hero-container">
                    <div className="hero-container-inner">
                        <div className="landing-page-hero-section">
                            <p className="lp-big-text">Unleash Your Digital</p>
                            <p className="lp-big-text mt--15">Dreams with <span className="red">Digital Crew</span></p>
                            <br />
                            <span className="lp-small-text mt--15">Transform Your Ideas with Our Expert Solutions</span>
                        </div>

                        <div className="lp-contact-form">
                            {step === 1 ? (
                                <>
                                    <h5>What games are you interested in developing?</h5>
                                    <div className="game-options">
                                        {["Rummy", "Poker", "Fantasy", "Multi-Gaming Platform", "Sports Betting & Casino", "Others"].map((game) => (
                                            <Button
                                                key={game}
                                                className={`mt-2`}
                                                onClick={() => handleGameSelect(game)}
                                                style={{
                                                    display: 'block',
                                                    margin: '10px auto',
                                                    width: '270px',
                                                    backgroundColor: selectedGame === game ? '#D97C7B' : '#F69C9B',
                                                    borderRadius: '25px',
                                                    border: 'none',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    color: 'white'
                                                }}
                                            >
                                                {game}
                                            </Button>
                                        ))}
                                    </div>

                                    {/* Next button to move to contact form */}
                                    <Button
                                        className="mt-3"
                                        onClick={handleNextStep}
                                        disabled={!selectedGame} // Disable Next button until a game is selected
                                        style={{
                                            display: 'block',
                                            margin: '20px auto', // Centering the button
                                            width: '200px',      // Set the button width
                                            padding: '10px',     // Adjust padding for spacing
                                            textAlign: 'center',
                                            backgroundColor: '#F44336',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        Next
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h2>Contact Us</h2>
                                    <p>Get Response Within 24 Hours</p>
                                    <form onSubmit={submitFormHandler}>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter Your Name"
                                            value={contactFormFields.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="mobile"
                                            placeholder="Enter Mobile Number"
                                            value={contactFormFields.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your Email"
                                            value={contactFormFields.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <textarea
                                            name="projectdescription"
                                            placeholder="Write Your Message"
                                            value={contactFormFields.projectdescription}
                                            onChange={handleChange}
                                            required
                                        />
                                        <button type="submit">SUBMIT</button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            ) : page === "thankyou-page" ? (
                <Container className={`hero-container-${page}-bg hero-container`}>

                </Container>
            ) : (
                <Container className={`hero-container-${page}-bg hero-container`}>
                    {page === "home" && (
                        <div className="pic-wrapper">
                            <figure className="pic-1"></figure>
                            <figure className="pic-2"></figure>
                            <figure className="pic-3"></figure>
                            <figure className="pic-4"></figure>
                            <figure className="pic-5"></figure>
                            <figure className="pic-6"></figure>
                        </div>
                    )}
                    <div className="hero-container-inner">
                        <div className="hero-section1">
                            <div className="section-1-inner">
                                <span
                                    className="hero-bigText"
                                    style={{ textAlign: page === "home" && "left" }}
                                    dangerouslySetInnerHTML={{ __html: bigText }}
                                ></span>
                                {page === "portfolio" ? (
                                    <>
                                        <div className="portfolio-hero-section">
                                            <div className="info-section">
                                                <p className="big-number">10+</p>
                                                <p className="red-text">Mobile Apps</p>
                                            </div>
                                            <div className="info-section">
                                                <p className="big-number">81+</p>
                                                <p className="red-text">Web Apps</p>
                                            </div>
                                            <div className="info-section">
                                                <p className="big-number">21+</p>
                                                <p className="red-text">Services</p>
                                            </div>
                                        </div>
                                    </>
                                ) : page === "aboutus" ? (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                    </>
                                ) : page === "games" ? (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                    </>
                                ) : (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                        <Button
                                            className={
                                                page === "home"
                                                    ? "hero-button-red-to-grey"
                                                    : "hero-button-grey-to-red"
                                            }
                                        >
                                            <a href="/contact-us">
                                                {buttonText}{" "}
                                                {page === "home" && <i className="icon play-btn" />}
                                            </a>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            )}
              <CustomToast show={showToast} onClose={handleHideToast} message={toastMessage} />
        </>
    );
}

export default HeroComponent;
