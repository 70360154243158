import React from 'react'
import SharedNavbar from '../components/SharedNavbar'
import { Route, useLocation } from 'react-router-dom';

import Footer from '../components/SharedFooter';
import Home from '../pages/Home';
import { Switch } from 'react-router-dom';
import Industries from '../pages/Industries';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import Privacy from "../pages/Privacy"
import Services from '../pages/Services';
import ProductDev from '../pages/ServicePages/ProductDev';
import Portfolio from '../pages/Portfolio';
import Games from '../pages/PortfolioPages/Games';
import DCPoker from '../pages/PortfolioPages/GamesPages/DCPoker';
import CasinoGames from '../pages/PortfolioPages/GamesPages/CasinoGames';
import Rummy from '../pages/PortfolioPages/GamesPages/Rummy';
import FantasySports from '../pages/PortfolioPages/GamesPages/FantasySports';
import Ludo from '../pages/PortfolioPages/GamesPages/Ludo';
import Trivia from '../pages/PortfolioPages/GamesPages/Trivia';
import BlackJack from '../pages/PortfolioPages/GamesPages/BlackJack';
import TeenPatti from '../pages/PortfolioPages/GamesPages/TeenPatti';
import ThinkingAndDesign from '../pages/ServicePages/ThinkingAndDesign';
import SoftwareDev from '../pages/ServicePages/SoftwareDev';
import ProductGrowth from '../pages/ServicePages/ProductGrowth';
import ResearchAndInnovative from '../pages/ServicePages/ResearchAndInnovative';
import Metaverse from '../pages/IndustryPages/Metaverse';
import Blockchain from '../pages/IndustryPages/Blockchain';
import AIML from '../pages/IndustryPages/AIML';
import Fintech from '../pages/IndustryPages/Fintech';
import Healthcare from '../pages/IndustryPages/Healthcare';
import Whatsapp from '../components/FloatingIcons/Whatsapp';
import LandingPage from "../pages/landingPage/landingPage"
import LandingPageTwo from "../pages/landingPage2/landingPage"
import ThankyouPage from "../pages/thankyouPage"
import ThankyouPageSoft from "../pages/thankyouPageSoft"
import Blog from "../../src/pages/Blog/Blog"
import BlogDetail from "../../src/pages/Blog/DetailsPage"
import My404Component from '../pages/My404Component';

function PublicLayout() {

    const location = useLocation();

    // Define the routes where WhatsApp component should be hidden
    const hiddenRoutes = [
        '/landing-page/igaming',
        '/landing-page/software-development',
        '/thankyou-page/igaming',
        '/thankyou-page/software-development',
        '/blog-detail'
        
    ];

    // Check if the current path is in hidden routes
    const shouldHideWhatsApp = hiddenRoutes.includes(location.pathname);
    
    return (
        <>
            <SharedNavbar />
            <Switch>
          
                <Route exact path="/" component={Home}></Route>
                <Route exact path="/industries" component={Industries}></Route>
                <Route exact path="/about-us" component={AboutUs}></Route>
                <Route exact path="/contact-us" component={ContactUs}></Route>
                <Route exact path="/privacy-policy" component={Privacy}></Route>
                <Route exact path="/services" component={Services}></Route>
                <Route exact path="/portfolio" component={Portfolio}></Route>
                <Route exact path="/services/product-development" component={ProductDev}></Route>
                <Route exact path="/services/thinking-and-design" component={ThinkingAndDesign}></Route>
                <Route exact path="/services/software-development" component={SoftwareDev}></Route>
                <Route exact path="/services/product-growth" component={ProductGrowth}></Route>
                <Route exact path="/services/research-and-innovative" component={ResearchAndInnovative}></Route>
                <Route exact path="/industries/igaming" component={Games}></Route>
                <Route exact path="/industries/games/dc-poker" component={DCPoker}></Route>
                <Route exact path="/industries/games/teen-patti" component={TeenPatti}></Route>
                <Route exact path="/industries/games/blackjack" component={BlackJack}></Route>
                <Route exact path="/industries/games/trivia" component={Trivia}></Route>
                <Route exact path="/industries/games/ludo" component={Ludo}></Route>
                <Route exact path="/industries/games/fantasy-sports" component={FantasySports}></Route>
                <Route exact path="/industries/games/rummy" component={Rummy}></Route>
                <Route exact path="/industries/games/casino-games" component={CasinoGames}></Route>
                <Route exact path="/industries/metaverse" component={Metaverse}></Route>
                <Route exact path="/industries/blockchain" component={Blockchain}></Route>
                <Route exact path="/industries/ai-ml" component={AIML}></Route>
                <Route exact path="/industries/fintech" component={Fintech}></Route>
                <Route exact path="/industries/healthcare" component={Healthcare}></Route>
                <Route exact path="/landing-page/igaming" component={LandingPage}></Route>
                <Route exact path="/landing-page/software-development" component={LandingPageTwo}></Route>
                <Route exact path="/thankyou-page/igaming" component={ThankyouPage}></Route>
                <Route exact path="/thankyou-page/software-development" component={ThankyouPageSoft}></Route>
                <Route exact path="/blog" component={Blog}></Route>
                <Route exact path="/blog-detail/:id" component={BlogDetail}></Route>
                <Route exact path="/thankyou-page/software-development" component={ThankyouPageSoft}></Route>
                <Route exact path="*" component={My404Component} />
            </Switch>
            {!shouldHideWhatsApp && <Whatsapp />}
            <Footer />
        </>
    )
}

export default PublicLayout