import React, { useEffect } from 'react'
import HeroComponent from '../../components/Hero'
import { Button, Carousel, Col, Image, Row } from 'react-bootstrap'
import BirdAndNest from '../../assests/images/birdandnest.png'
import HelmetComponent from '../../components/Helmet';
import SEO from '../../components/SEO';


function ResearchAndInnovative() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
         <SEO
        title="Research and Innovative - Digital Crew"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/services/research-and-innovative"
      />
            <HelmetComponent title={"Research and Innovative - Digital Crew"} description={"We create value added designs by combining imagination, creativity, and innovation. Hire a creative designer for the development of your software, app and web services"} url="services/research-and-innovative" />  
            <HeroComponent
                bigText={"Get A Properly Researched Product Development For Your Domain."}
                smallText={"Let’s connect to the professional research and innovation team."}
                buttonText={"Book Our Demo"}
                page={"services-research"}
            ></HeroComponent>
            <div className="research-and-innovative-section">
                <div className="section1">
                    <p className="breadcrumb">
                    <a href="/services">Services</a> <span className="active"> &gt; Research And Innovation</span>
                    </p>
                </div>

                <div className="section3">
                    <h2 className="mid-title">
                        <span className="redblock">Sorry,</span> The Page You Are Looking<br />
                        For Is Not Available.
                    </h2>
                    <p className="midsmalltext">
                        The link you followed may be broken, or the page may have been removed.<br/>
                        Go back to <a href='/' className="hvr-grow">homepage</a>
                         </p>
                    <div className="mid-section">
                       <img src={BirdAndNest} alt="Bird-and-nest" />
                    </div>
                </div>
                <div className='contact-section'>
                    <p className='midtitle'>Get in touch</p>
                    <p className='midsmalltext bottom'>We’d like to hear from you and will be happy to answer your queries</p>
                </div>
            </div>
        </>
    )
}

export default ResearchAndInnovative