import React, { useState } from "react";
import { Link } from "react-router-dom";
import blogData from "./data";
import "./Blog.css";
import SEO from "../../components/SEO";
import HeroComponent from "../../components/Hero";
import TelegramQR from "../../../src/assests/images/telegram_QR.png";
import WhatsAppQR from "../../../src/assests/images/WhatsApp_QR.png";

const BlogCard = ({ id, title, image, desc }) => {
  const truncatedDescription =
    desc.length > 150 ? `${desc.slice(0, 150)}...` : desc;
  return (
    <Link to={`/blog-detail/${id}`} className="blog-card-link" style={{ textDecoration: "none" }}  target="_blank"
    rel="noopener noreferrer">
      <div className="blog-card">
        <img src={image} alt={title} className="blog-card-image" />
        <h4 style={{ color: "black" }}>{title}</h4>
        <p dangerouslySetInnerHTML={{ __html: truncatedDescription }} style={{ color: "black" }} />
      </div>
    </Link>
  );
};

const Sidebar = ({ searchText, setSearchText, handleSearch }) => {
  return (
    <div className="sidebar">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            padding: "8px 12px",
            border: "none",
            cursor: "pointer",
            borderRadius:"4px"
          }}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="banner">
        {/* <img src="https://via.placeholder.com/300x250" alt="Banner" /> */}
      </div>
      <div className="qr-code">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={WhatsAppQR} style={{ width: "50%" }} alt="WhatsApp QR" />
          <img src={TelegramQR} style={{ width: "50%" }} alt="Telegram QR" />
        </div>
        <p>Scan QR Code for Immediate Contact</p>
      </div>
      <div className="contact-form">
        <h3>Contact Us</h3>
        <input type="text" placeholder="Your Name" />
        <input type="email" placeholder="Your Email" />
        <input type="phone" placeholder="Your Mobile Number" />
        <textarea placeholder="Your Message"></textarea>
        <button style={{backgroundColor:"#f44336"}}>Submit</button>
      </div>
    </div>
  );
};

const BlogList = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState(blogData);

  const handleSearch = () => {
    if (searchText.trim() === "") {
      alert("Please enter text to search.");
      return;
    }
    const matchedBlogs = blogData.filter((blog) =>
      blog.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBlogs(matchedBlogs);
  };

  const firstBlog = filteredBlogs.slice(0, 1); // Display first blog prominently
  const otherBlogs = filteredBlogs.slice(1); // Remaining blogs for grid display

  return (
    <div className="blog-list">
      <SEO
        title="Digital Crew Blog | Insights on Technology, Marketing, and Innovation"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/blog"
      />
      {/* <HeroComponent
        bigText="<span> Blog - Digital Crew </span>"
        smallText="Dive into our latest posts to stay updated with industry trends and innovations"
        page="aboutus"
      /> */}

      <div className="blog-layout">
        <div className="main-content">
          {/* Display the first blog prominently */}
          {filteredBlogs.length > 0 ? (
            <>
              <div className="featured-blog">
                {firstBlog.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    image={blog.image}
                    desc={blog.description}
                  />
                ))}
              </div>

              {/* Display remaining blogs in a grid layout */}
              <div className="card-grid">
                {otherBlogs.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    image={blog.image}
                    desc={blog.description}
                  />
                ))}
              </div>
            </>
          ) : (
            <p style={{ color: "black" }}>No blogs found matching your search.</p>
          )}
        </div>

        <Sidebar
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default BlogList;
