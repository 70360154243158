import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import HeroComponent from "../components/Hero";
import ServiceCard from "../components/ServiceCard";
import Fade from "react-reveal/Fade";
import { submitContactForm } from "../services";
import CustomToast from "../components/Toast";
import HelmetComponent from "../components/Helmet";
import SEO from "../components/SEO";
import { useHistory } from 'react-router-dom';

function Services() {
  const history = useHistory();
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
      history.push("/thankyou-page/igaming");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Top Web, software, and Mobile App Development Services - Digital Crew"
        description="Are you seeking a top website, software, and mobile app development company? Digital Crew offers the most fast, secure web and app development services."
        url="https://digitalcrew.co.in/services"
        keywords="product development services, concept development services, data & analytics services, healthcare software development, best market research companies, custom software development companies, android game development,
        mobile game development, best mobile game development companies, video game development companies"
      />
      {/* <HelmetComponent title={"Software & Game Development Services - Digital Crew "} description={"Start working with the team of software and application development domain experts. hire Digital Crew to build world class gaming app and software."} url="services" />   */}

      <HeroComponent
        bigText={
          "Start working with the team of Software and application development domain experts."
        }
        smallText={
          "With creativity and innovative strategies, our professionals create super-rich,<br /> ROI driven growth for your business."
        }
        buttonText={"Consult Our Experts"}
        page={"services"}
      ></HeroComponent>

      <div className="services-section">
        <div className="section1">
          <div className="red-box">
            <div className="redbox-inner">
              <a href="/services/product-development">
                <i className="icon prod-dev-red"></i>
                <div className="right-side">Product Development</div>
              </a>
            </div>
          </div>
          <div className="red-box">
            <div className="redbox-inner">
              <a href="/services/thinking-and-design">
                <i className="icon red-bulb"></i>
                <div className="right-side">Thinking and Designing</div>
              </a>
            </div>
          </div>

          <div className="red-box">
            <div className="redbox-inner">
              <a href="/services/software-development">
                <i className="icon red-computer-icon"></i>
                <div className="right-side">Software Development</div>
              </a>
            </div>
          </div>

          <div className="red-box">
            <div className="redbox-inner">
              <a href="/services/product-growth">
                <i className="icon growth-icon-red"></i>
                <div className="right-side">Product Growth</div>
              </a>
            </div>
          </div>

          <div className="red-box">
            <div className="redbox-inner">
              <a href="/services/research-and-innovative">
                <i className="icon red-bulb-icon"></i>
                <div className="right-side">Research and Innovation</div>
              </a>
            </div>
          </div>
        </div>
        <ServiceCard
          heading={"Product Development"}
          para={
            "Digital Crew has the best team and use latest technologies to ensure a seamless end-to-end software development process for the full life cycle of product development. Our product development team aims to take the product to the targeted market and work to improve it until we achieve the targeted business goals.<br/>We have a set of all the needed processes for complete product development services from a rough idea with a prototype, testing the desirable product with a minimum viable product (MVP), introducing it to the target market, and finally scaling for more advanced and perfect features."
          }
          servicesHeading={"Product Development Services"}
          serviceSteps={[
            "Concept Development",
            "Build and Test",
            "Optimize",
            "Launch",
          ]}
          type="product-development"
        ></ServiceCard>
        <ServiceCard
          heading={"Thinking and Designing"}
          para={
            "Great web design without functionality is like a sports car without an engine. The Digital Crew team of experts understands that a highly featured website can help businesses to build a strong name among the competitors and helps to drive more traffic and meet business goals and targets. Website design customization is our strength; here we create impactful outputs for your business growth in this digital era.<br/>Your website is your business personality, image, and quality of your business to the users who are accessing it. Our web designers with creative team can help you to introduce your brand with a great impression on the market."
          }
          servicesHeading={"Thinking and Designing Services"}
          serviceSteps={["Creativity", "Designing", "Testing"]}
          type="thinking-and-design"
          reverse
        ></ServiceCard>
        <ServiceCard
          heading={"Software Development"}
          para={
            "IT is the engine that drives today’s business. The Digital Crew team helps our clients with advanced technology to increase business growth by providing them with the best software and application development services across the world. We tend to provide the best digital solutions at a pocket-friendly price. We are rated as the best software and app Development Company in India. Our software developer always keeps an eye on trends and all the tiny things that matter for your website’s visual aesthetics.<br/>Mostly appreciated software and application development services by our clients are Sports Betting, iGaming, Poker, Rummy and many more."
          }
          servicesHeading={"Software Development Services"}
          serviceSteps={["Innovation", "Development", "Testing", "Launch"]}
          type="software-development"
        ></ServiceCard>
        <ServiceCard
          heading={"Product Growth"}
          para={
            "If you want continuous business growth, a continuous growth plan for your product is highly essential. We are on our way to ensuring the continued success of your product even after it leaves our workplace. Our team of experts dedicatedly works to ensure that your digital solution is future-proof across generations & world with the highest quality and better durability.<br/>Hire the best developers and accelerate your business and revenue. A one-stop platform for all your digital solutions. Let’s connect with the world's best company for all your digital solutions and software development services."
          }
          servicesHeading={"Product Growth Services"}
          serviceSteps={[
            "Market Research",
            "Quality Assurance",
            "Business Analytics",
            "Launch",
          ]}
          type="product-growth"
          reverse
        ></ServiceCard>
        <ServiceCard
          heading={"Research and Innovation"}
          para={
            "To build efficient product great research and timely innovation is required. Our highly experienced team of research and innovation department works on your idea to make it more sustainable and real. Complete research and development help us to deliver your product by stimulating, fostering, and enhancing, emerging technologies to future capabilities with an efficient product.<br/>Better research will help you to grow your business with strategies and the right decision to gain an edge above your competitors. This will help you to identify what extra you should do to add more value to your product and service through real insights."
          }
          servicesHeading={"Research and Innovation Services"}
          serviceSteps={[
            "Business Analytics",
            "Research and Scope",
            "Technology Adaption",
            "Tested Launch",
          ]}
          type="research-and-innovative"
        ></ServiceCard>

        <div className="contact-section">
          <p className="midtitle">
            Share your estimate and custom plan for your business
          </p>
          <p className="midsmalltext">
            Our expert helps you with the best solutions for product growth
          </p>
          <div className="contact-box">
            <Row>
              <Col lg={8}>
                <form
                  encType="multipart/form-data"
                  onSubmit={submitFormHandler}
                >
                  <div className="contact-form">
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/*  <Col lg={6}>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.docx" placeholder="" name="file" onChange={handleChange} />
                                                <Button className='btn btn-file'>+</Button>
                                                {contactFormFields.file !== null && <p>{contactFormFields.file?.name}</p>}
                                            </div>

                                            <p>Attached File Less then 25 mB</p>
                                        </Col> */}
                      <Col lg={12}>
                        <Button className="btn contact-form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
              <Col lg={4}>
                <div className="right-side-inner">
                  <Fade bottom>
                    <p className="bigtext">
                      Let's Get into
                      <br />
                      <span className="red-text">Business discussions </span> !!
                    </p>
                    <p className="smallText">
                      Our strength lies in the comprehensive suite of services
                      we offer to our clients.
                    </p>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
          <CustomToast
            show={showToast}
            onClose={handleHideToast}
            message={toastMessage}
          ></CustomToast>
        </div>
      </div>
    </>
  );
}

export default Services;
