import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import blogs from './data'; // Array of blog objects with id, title, description, image, and content properties
import './Blog.css';
import SEO from '../../components/SEO';
import HeroComponent from '../../components/Hero';
import TelegramQR from "../../../src/assests/images/telegram_QR.png";
import WhatsAppQR from "../../../src/assests/images/WhatsApp_QR.png";

const Sidebar = ({ blogs }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = () => {
    if (searchText.trim() === "") {
      alert("Please enter text to search.");
      return;
    }

    const results = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchResults(results);
  };

  return (
    <div className="sidebar">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            padding: "8px 12px",
            border: "none",
            cursor: "pointer",
            borderRadius:"4px"
          }}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>

      {/* Display search results */}
      {searchResults.length > 0 && (
        <div className="search-results">
          <h4>Search Results:</h4>
          <ul>
            {searchResults.map((result) => (
              <li key={result.id}>
                <a
                  href={`/blog-detail/${result.id}`}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {result.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="banner">
        {/* <img src="https://via.placeholder.com/300x250" alt="Banner" /> */}
      </div>
      <div className="qr-code">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={WhatsAppQR} style={{ width: "50%" }} alt="WhatsApp QR" />
          <img src={TelegramQR} style={{ width: "50%" }} alt="Telegram QR" />
        </div>
        <p>Scan QR Code for Immediate Contact</p>
      </div>
      <div className="contact-form">
        <h3>Contact Us</h3>
        <input type="text" placeholder="Your Name" />
        <input type="email" placeholder="Your Email" />
        <input type="phone" placeholder="Your Mobile Number" />
        <textarea placeholder="Your Message"></textarea>
        <button style={{backgroundColor:"#f44336"}}>Submit</button>
      </div>
    </div>
  );
};

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find((item) => item.id === parseInt(id, 10));

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="blog-detail">
      <SEO
        title={blog.title}
        description={blog.description}
        url={`https://digitalcrew.co.in/blog/${id}`}
      />
      {/* <HeroComponent
      bigText={`<span>${blog.title}</span>`}
      // smallText={blog.description}
      blogImage={blog.image}
      page="aboutus"
    /> */}

      <div className="blog-layout">
        <div className="main-content">
          <h2 className="blog-title">{blog.title}</h2>
          <div className="banner">
            <img src={blog.image} alt={blog.title} />
          </div>
          <div className="blog-content">
            <p
              dangerouslySetInnerHTML={{ __html: blog.description }}
              style={{ color: "black" }}
            />
          </div>
        </div>
        {/* Pass the blogs array to Sidebar for searching */}
        <Sidebar blogs={blogs} />
      </div>
    </div>
  );
};

export default BlogDetail;
